<template>
    <div>
       <NavBar v-if="!$route.path.includes('rule') && $route.path !== '/'"/>
        <router-view class="middle-container"> </router-view>
        <Footer v-if="!$route.path.includes('rule') && $route.path !== '/'"/>
        <div class="social-contacts" id="social-contacts" v-if="generateCart()">
            <div
                style="position: relative"
                class="cart-container"
                v-if="
                    $route.path === '/user/2d' ||
                    $route.path === '/user/3d' ||
                    $route.path.includes('maung-fb') ||
                    $route.path.includes('body-fb')
                "
                @click="openModalVoucher()"
            >
                <div>
                    <i class="ni ni-cart mb-2"></i>
                </div>
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('2d')"
                    >{{ $store.state.twod_slip_data.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('fb')"
                    >{{ $store.state.fb_order.fb_matches.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-else-if="$route.path.includes('3d')"
                    >{{ $store.state.threed_slip_data.length }}</span
                >
            </div>
        </div>
        <div class="col-4">
                <div
                    class="modal fade"
                    id="version"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    @click="closeModalOutside($event)"
                >
                  <div
                      class="modal-dialog modal- modal-dialog-centered"
                      role="document"
                  >
                      <div class="modal-content">
                          <div class="modal-body my-5 mx-3 text-md text-danger text-center">
                            {{
                                $store.state.language === "en"
                                    ? "Your current application is out of date. Please refresh OR download again to get New Updated App."
                                    : "ယခုလက်ရှိ အသုံးပြုနေသော applicationမှာ version အဟောင်း ဖြစ်နေပါသည်။ ထို့ကြောင့် version အသစ်ဖြင့်အသုံးပြုနိုင်ရန်အတွက် refresh လုပ်ပေးပါရန် (သို့) application အား ပြန်လည် download ပြုလုပ်ပေးရန် လိုအပ်ပါသည်။"
                            }}
                            <br><br> <span class="text-dark text-sm">
                            {{
                                $store.state.language === "en"
                                    ? "Thanks from V1 ရှမ်းကိုးမီး Admin Team."
                                    : "V1 ရှမ်းကိုးမီး admin အဖွဲ့မှ ကျေးဇူးတင်ပါသည်။"
                            }}
                            </span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </div>
</template>
<script>
import { Dialog } from 'vant';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  async mounted() {
    $('#welcome').modal('show');
    this.fetchWebsiteData()
    this.fetchUser();
    const isLoggedIn = localStorage.getItem('token');
    const loggedInUser = await this.fetchUser();

    if(!loggedInUser && this.$route.path !== '/auth/login'){
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      this.$router.push('/auth/login');
    }

    if (isLoggedIn) {
      this.$store.commit(
        'setAmount',
        parseInt(this.$store.state.authUser.amount, 10),
      );
    }

    if (!localStorage.getItem('token') && this.$route.path !== '/auth/login' && this.$route.path !== '/') {
      this.$router.push('/auth/login');
    }

    if (isLoggedIn && this.$store.state.authUser.roles !== 'normal' && this.$route.path !== '/auth/login') {
      this.$router.push('/auth/login');
    }
  },

  computed: {
    ...mapGetters(['getFBOrder']),
  },

  methods: {
    ...mapMutations(['setWholeFBMatches']),
    ...mapActions(['fetchUser']),
    generateCart() {
      if(this.$route.path.includes('2d')){
          return this.$store.state.twod_slip_data.length == 0 ? false : true
      } else if(this.$route.path.includes('3d')){
          return this.$store.state.threed_slip_data.length == 0 ? false : true
      } else {
          return this.$store.state.fb_order.fb_matches.length == 0 ? false : true
      }
    },
    startAudioPlayback() {
      // Access the audio element using the ref attribute
      const audio = this.$refs.audioPlayer;
      
      // Start audio playback
      audio.play();
      $('#welcome').modal('hide');
    },
    closeModalOutside(event) {
      if (event.target.classList.contains('modal')) {
        this.$router.go();
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        console.log('this.$store.state.androidVersion', this.$store.state.androidVersion);
        // if(res.data.data[0].androidVersion !== this.$store.state.androidVersion) {
        //   $('#version').modal('show');
        // }
      } catch (error) {
        console.log(error);
      }
    },
    openModalVoucher() {
      const type = this.$route.path.includes('2d') || this.$route.path.includes('3d') ? 'number' : '';
      if (type === 'number') {
        $('#mobile-voucher').modal('show');
      } else if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError = this.$store.state.language === 'en'
          ? 'Please select some teams!'
          : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        if (!this.$store.state.betAmount) {
          $('#amount').modal('show');
        } else {
          for (let i = 0; i < matches.length; i += 1) {
            matches[i].bet_amount = this.$store.state.betAmount;
          }
          this.setWholeFBMatches(matches);
          $('#mobile-voucher').modal('show');
        }
      }
    },

    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style scoped>
.cart-number {
    top: -2px !important;
    left: -2px !important;
}
.cart-number {
    position: absolute;
    top: -3px;
    left: -3px;
    text-align: center;
}
.middle-container {
    min-height: 75vh;
}
audio {
  display: none; /* Hide the audio player visually */
}
@media (max-width: 768px) {
    .cart-container {
        font-size: 30px !important;
    }
    .social-contacts div {
        width: 60px !important;
        height: 60px !important;
        margin-top: 15px;
    }
    #social-contacts .badge-circle.badge-md {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
    .cart-container {
        visibility: visible;
        font-size: 16px;
    }
    .middle-container {
        min-height: 100vh;
        margin: 0px !important;
        /* padding-top: 0.1rem !important; */
        /* margin-bottom: 50px !important; */
    }
}
</style>
