<template>
    <div class="d-flex pt-5 justify-content-center" style="background-image: url('/build/assets/img/theme/bg.png'); background-size: cover; height: 100vh;">
        <!-- <div class="col-5 home-cards pr-2 pl-0 mt-3" @click="redirectRoute('user/slots', {provider: 'Jili'})">
            <img src="/build/assets/img/theme/jili.png" alt="" style="width: 100%; height: 11.5rem; border-radius: 5px" />
        </div>
        <div class="col-5 home-cards pl-2 pr-0 mt-3" @click="redirectRoute('user/slots', {provider: 'Pragmatic'})">
            <img src="/build/assets/img/theme/pragmatic.png" alt="" style="width: 100%; height: 11.5rem; border-radius: 5px" />
        </div> -->
        <div
            class="col-xl-6 col-6 home-cards"
            @click="redirectRoute('/user/slots', {provider: 'Pragmatic'})"
            v-if="$store.state.authUser.parent_info.is_slot_enabled"
        >
            <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                <img
                    src="/build/assets/img/theme/pp.png"
                    alt=""
                    style="width: 100%; height: 200px;"
                />
            </div>
        </div>
        <div
            class="col-xl-6 col-6 home-cards"
            @click="redirectRoute('/user/slots', {provider: 'Jili'})"
            v-if="$store.state.authUser.parent_info.is_slot_enabled"
        >
            <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                <img
                    src="/build/assets/img/theme/jili.png"
                    alt=""
                    style="width: 100%; height: 200px;"
                />
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    methods:{
        async redirectRoute(path, query = null) {
                this.$router.push({ path, query });
        }
    }
}
</script>