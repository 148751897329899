<template>
    <div class="container-fluid d-flex flex-wrap mt-5 main-wrapper">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-9 col-12 p-0 mb-5">
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">
                                {{
                                    $store.state.language === "en"
                                        ? "Withdraw"
                                        : "ငွေထုတ်"
                                }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <h6 class="heading-small text-muted mb-4">
                            {{
                                $store.state.language === "en"
                                    ? "Account information"
                                    : "အကောင့်ဆိုင်ရာ အချက်အလက်များ"
                            }}
                        </h6>
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-username"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Choose Deposit Type"
                                                    : "ငွေထုတ်ယူမူပုံစံ ရွေးချယ်ပါ"
                                            }}</label
                                        >
                                        <AccountSelect
                                            :data="selected_account"
                                            field="account_id"
                                            :accounts="accounts"
                                        />
                                        <span class="text-danger">{{
                                            errors.account
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="my-4" />
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Account Number"
                                                    : "အကောင့် နံပါတ်"
                                            }}</label
                                        >
                                        <input
                                            type="number"
                                            class="form-control"
                                            v-model="
                                                withdrawData.account_number
                                            "
                                        />
                                        <span class="text-danger">{{
                                            errors.number
                                        }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label">{{
                                            $store.state.language === "en"
                                                ? "Amount"
                                                : "ငွေပမာဏ"
                                        }}</label>
                                        <span class="text-danger text-xs">{{
                                            $store.state.language === "en"
                                                ? "( Minimum amount 1,000 )"
                                                : "( အနည်းဆုံး 1,000 )"
                                        }}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            v-model="withdrawData.amount"
                                        />
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4">
                            <div class="form-group">
                                <label class="form-control-label">{{
                                    $store.state.language === "en"
                                        ? "Remark"
                                        : "မှတ်ချက်"
                                }}</label>
                                <textarea
                                    rows="4"
                                    class="form-control"
                                    v-model="withdrawData.remark"
                                ></textarea>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="sendWithdrawData"
                                >
                                    <div v-if="!isConfirm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Confirm"
                                                : "အတည်ပြုမည်"
                                        }}
                                    </div>
                                    <Loading
                                        color="#ffffff"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
// import SideNav from './components/SideNav.vue';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      selected_account: {
        account_id: null,
      },
      accounts: [
        {
          name: 'KBZ pay',
          id: 'KBZ pay',
        },
        {
          name: 'Wave pay',
          id: 'Wave pay',
        },
        {
          name: 'KBZ Mobile Banking',
          id: 'KBZ Mobile Banking',
        },
        {
          name: 'Yoma',
          id: 'Yoma',
        },

      ],
      account: [],
      imageUploader: [],
      withdrawData: {},
      errors: {},
      isConfirm: false,
    };
  },
  methods: {
    // async fetchAccounts() {
    //   try {
    //     const res = await axios.get('/accounts');
    //     this.accounts = res.data.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async sendWithdrawData() {
      try {
        this.errors = {};
        if (!this.selected_account.account_id) {
          this.errors.account = this.$store.state.language === 'en'
            ? 'Please pick account type'
            : 'အကောင့်အမျိုးအစား ရွေးချယ်ပေးပါ။';
          return false;
        }
        if (!this.withdrawData.account_number) {
          this.errors.number = this.$store.state.language === 'en'
            ? 'Please enter account number'
            : 'အကောင့်နံပါတ် ထည့်သွင်းပေးပါ။';
          return false;
        }
        if (!this.withdrawData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
          return false;
        }
        if (this.withdrawData.amount < 100) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter at least 1000 MMK'
            : 'ငွေပမာဏအနည်းဆုံး ၁၀၀၀ ထည့်ပေးပါ။';
          return false;
        }
        if (this.withdrawData.amount > this.$store.state.amount) {
          const balanceError = this.$store.state.language === 'en'
            ? "You Don't Have Sufficient Balance"
            : 'အကောင့်ထဲတွင် ပိုက်ဆံအလုံအလောက် မရှိပါ။';
          Dialog.alert({
            message: balanceError,
            confirmButtonText:
                            this.$store.state.language === 'en'
                              ? 'Go Back'
                              : 'နောက်သို့ပြန်သွားမည်။',
          }).then(() => {
            // on close
          });
          return false;
        }
        if (this.isConfirm) {
          return false;
        }
        this.$store.commit(
          'setAmount',
          (this.$store.state.amount -= this.withdrawData.amount),
        );
        this.isConfirm = true;
        this.withdrawData.type = this.selected_account.account_id;
        this.withdrawData.date = this.currentTime;
        const res = await axios.post('/withdraws', this.withdrawData);
        console.log('hello 1');
        const resUser = await axios.post(
          `/admin/user/${this.$store.state.authUser.id}`,
          {
            amount: this.withdrawData.amount,
            type: 'withdraw',
          },
        );
        console.log('hello 2');
        this.withdrawData = {};
        this.selected_account.account_id = null;
        Toast.success('Success');
        this.isConfirm = false;
      } catch (error) {
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  //   watch: {
  //     'selected_account.account_id': function () {
  //       this.account = this.accounts.filter(
  //         (account) => account.id === this.selected_account.account_id,
  //       );
  //     },
  //   },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  mounted() {
    // this.fetchAccounts();
    // if (!this.$auth.user().is_verified) {
    //   this.$router.push('/auth/verify');
    // }
  },
};
</script>
<style scoped>
.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: #e9ecef;
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
