<template>
    <div class="group-container">
        <div class="card px-4 pb-4" style="min-height: 240px; height: auto">
            <div
                class="card-header font-weight-bold text-center border-bottom-0"
            >
                {{
                    $store.state.language === "en"
                        ? "Two-D Group"
                        : "အုပ်စုလိုက်ထိုးမည်"
                }}
            </div>
            <div class="d-flex flex-wrap">
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#even_even"
                    >{{
                        $store.state.language === "en" ? "Even Even" : "စုံစုံ"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    style="text-align: center"
                    data-toggle="modal"
                    data-target="#odd_odd"
                    >{{ $store.state.language === "en" ? "Odd Odd" : "မမ" }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#even_odd"
                    >{{ $store.state.language === "en" ? "Even Odd" : "စုံမ" }}
                </a>
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#odd_even"
                    >{{
                        $store.state.language === "en" ? "Odd Even" : "မစုံ"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#brother"
                    >{{
                        $store.state.language === "en" ? "Brother" : "ညီကို"
                    }}</a
                >

                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#constellation"
                    >{{
                        $store.state.language === "en"
                            ? "Constellation"
                            : "နက္ခတ်"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#front_serie"
                    >{{
                        $store.state.language === "en"
                            ? "Front Serie"
                            : "ရှေ့စီးရီး"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#back_serie"
                    >{{
                        $store.state.language === "en"
                            ? "Back Serie"
                            : "နောက်စီးရီး"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#only_one"
                    >{{
                        $store.state.language === "en"
                            ? "Only One"
                            : "တစ်လုံးပါ"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#double"
                    >{{ $store.state.language === "en" ? "Double" : "အပူး" }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#double_even"
                    >{{
                        $store.state.language === "en"
                            ? "Double(Even)"
                            : "စုံပူး"
                    }}</a
                >

                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#double_odd"
                    >{{
                        $store.state.language === "en" ? "Double(Odd)" : "မအပူး"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#brake"
                    >{{ $store.state.language === "en" ? "Brake" : "ဘရိတ်" }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#power"
                    >{{ $store.state.language === "en" ? "Power" : "ပါဝါ" }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#front_even"
                    >{{
                        $store.state.language === "en"
                            ? "Front Even"
                            : "ရှေ့စုံ"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#back_even"
                    >{{
                        $store.state.language === "en"
                            ? "Back Even"
                            : "နောက်စုံ"
                    }}</a
                >
                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#front_odd"
                    >{{
                        $store.state.language === "en" ? "Front Odd" : "ရှေ့မ"
                    }}</a
                >

                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#back_odd"
                    >{{
                        $store.state.language === "en" ? "Back Odd" : "နောက်မ"
                    }}</a
                >

                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#round_with_double"
                    >{{
                        $store.state.language === "en"
                            ? "Round With Double"
                            : "အပြီးပေါက်အပူးပါ"
                    }}</a
                >

                <a
                    href="#"
                    class="badge badge-pill badge-primary m-1"
                    data-toggle="modal"
                    data-target="#round_without_double"
                    >{{
                        $store.state.language === "en"
                            ? "Round Without Double"
                            : "အပြီးပေါက်အပူးမပါ"
                    }}</a
                >
            </div>
            <!-- === for even even === -->
            <InputSelectDrawModalVue
                id_type="even_even"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for odd odd === -->
            <InputSelectDrawModalVue
                id_type="odd_odd"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for even odd === -->
            <InputSelectDrawModalVue
                id_type="even_odd"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for odd even === -->
            <InputSelectDrawModalVue
                id_type="odd_even"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for front serie === -->
            <InputSelectDrawModalVue
                id_type="front_serie"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for back serie === -->
            <InputSelectDrawModalVue
                id_type="back_serie"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for back even === -->
            <InputSelectDrawModalVue
                id_type="back_even"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for front even === -->
            <InputSelectDrawModalVue
                id_type="front_even"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for front odd === -->
            <InputSelectDrawModalVue
                id_type="front_odd"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for back odd === -->
            <InputSelectDrawModalVue
                id_type="back_odd"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for double === -->
            <InputSelectDrawModalVue
                id_type="double"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === only one === -->
            <InputSelectDrawModalVue
                id_type="only_one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === brake === -->
            <InputSelectDrawModalVue
                id_type="brake"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for double (even) === -->
            <InputSelectDrawModalVue
                id_type="double_even"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for double (odd) === -->
            <InputSelectDrawModalVue
                id_type="double_odd"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for constellation === -->
            <InputSelectDrawModalVue
                id_type="constellation"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for brother === -->
            <InputSelectDrawModalVue
                id_type="brother"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for power === -->
            <InputSelectDrawModalVue
                id_type="power"
                modalType="one"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for round_without_double === -->
            <InputSelectDrawModalVue
                id_type="round_with_double"
                modalType="round"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />

            <!-- === for round_without_double === -->
            <InputSelectDrawModalVue
                id_type="round_without_double"
                modalType="round"
                :selected_data="selected_data"
                @type="getType"
                :drawTime="drawTime"
            />
        </div>
    </div>
</template>
<script>
import InputSelectDrawModalVue from './InputSelectDrawModal.vue';

export default {
  props: ['drawTime'],
  components: { InputSelectDrawModalVue },
  data() {
    return {
      selected_data: {},
      numbers: [],
      allNumbers: [],
    };
  },
  methods: {
    getType(value) {
      this.selectNumbers(value.id_type);
      this.setAmount(value.amount, value.drawTime);
      this.$emit('numbers', this.numbers);
    },
    setAmount(amount, drawTime) {
      // const currentHour = new Date().getHours();
      // const draw = currentHour < 12 ? '12:00' : '4:30';
      for (let i = 0; i < this.numbers.length; i += 1) {
        this.numbers[i].solo_amount = amount;
        this.numbers[i].draw = drawTime;
      }
    },
    selectNumbers(type) {
      switch (type) {
        case 'even_even':
          this.numbers = this.allNumbers.filter(
            (el) => parseInt(el.number, 10) % 2 === 0
                            && Math.floor(parseInt(el.number, 10) / 10) % 2 === 0,
          );

          break;
        case 'odd_odd':
          this.numbers = this.allNumbers.filter(
            (el) => parseInt(el.number, 10) % 2 !== 0
                            && Math.floor(parseInt(el.number, 10) / 10) % 2 !== 0,
          );

          break;
        case 'even_odd':
          this.numbers = this.allNumbers.filter(
            (el) => parseInt(el.number, 10) % 2 !== 0
                            && Math.floor(parseInt(el.number, 10) / 10) % 2 === 0,
          );
          break;
        case 'odd_even':
          this.numbers = this.allNumbers.filter(
            (el) => el.number % 2 === 0
                            && Math.floor(parseInt(el.number, 10) / 10) % 2 !== 0,
          );

          break;
        case 'double':
          this.numbers = this.allNumbers.filter((el) => {
            const lastChar = el.number.toString().slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            return (
              Math.floor(parseInt(el.number, 10) / 10)
                            === lastDigit
            );
          });

          break;
        case 'double_even':
          this.numbers = this.allNumbers.filter((el) => {
            const lastChar = el.number.toString().slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            return (
              Math.floor(parseInt(el.number, 10) / 10) % 2
                                === 0
                            && Math.floor(parseInt(el.number, 10) / 10)
                                === lastDigit
            );
          });

          break;
        case 'double_odd':
          this.numbers = this.allNumbers.filter((el) => {
            const toText = el.number.toString().toString();
            const lastChar = toText.slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            return (
              Math.floor(parseInt(el.number, 10) / 10) % 2
                                !== 0
                            && Math.floor(parseInt(el.number, 10) / 10)
                                === lastDigit
            );
          });

          break;
        case 'power':
          this.numbers = this.allNumbers.filter(
            (el) => parseInt(el.number, 10) === 5
                            || parseInt(el.number, 10) === 50
                            || parseInt(el.number, 10) === 16
                            || parseInt(el.number, 10) === 61
                            || parseInt(el.number, 10) === 27
                            || parseInt(el.number, 10) === 72
                            || parseInt(el.number, 10) === 38
                            || parseInt(el.number, 10) === 83
                            || parseInt(el.number, 10) === 49
                            || parseInt(el.number, 10) === 94,
          );

          break;
        case 'constellation':
          this.numbers = this.allNumbers.filter(
            (el) => parseInt(el.number, 10) === 96
                            || parseInt(el.number, 10) === 7
                            || parseInt(el.number, 10) === 70
                            || parseInt(el.number, 10) === 18
                            || parseInt(el.number, 10) === 81
                            || parseInt(el.number, 10) === 24
                            || parseInt(el.number, 10) === 42
                            || parseInt(el.number, 10) === 35
                            || parseInt(el.number, 10) === 53
                            || parseInt(el.number, 10) === 69,
          );

          break;
        case 'brother':
          this.numbers = this.allNumbers.filter(
            (el) => parseInt(el.number, 10) === 12
                            || parseInt(el.number, 10) === 23
                            || parseInt(el.number, 10) === 34
                            || parseInt(el.number, 10) === 45
                            || parseInt(el.number, 10) === 56
                            || parseInt(el.number, 10) === 67
                            || parseInt(el.number, 10) === 78
                            || parseInt(el.number, 10) === 89
                            || parseInt(el.number, 10) === 90
                            || parseInt(el.number, 10) === 9
                            || parseInt(el.number, 10) === 98
                            || parseInt(el.number, 10) === 87
                            || parseInt(el.number, 10) === 76
                            || parseInt(el.number, 10) === 65
                            || parseInt(el.number, 10) === 54
                            || parseInt(el.number, 10) === 43
                            || parseInt(el.number, 10) === 32
                            || parseInt(el.number, 10) === 21
                            || parseInt(el.number, 10) === 10
                            || parseInt(el.number, 10) === 1,
          );

          break;
        case 'front_serie':
          this.numbers = this.allNumbers.filter((el) => {
            if (
              this.selected_data.number_select === '0'
                            && typeof el.number === 'string'
            ) {
              return el.number;
            }
            return (
              Math.floor(el.number / 10)
                            === this.selected_data.number_select
            );
          });

          break;

        case 'back_odd':
          this.numbers = this.allNumbers.filter((el) => {
            if (
              this.selected_data.number_select === '0'
                            && typeof el.number === 'string'
                            && parseInt(el.number, 10) % 2 !== 0
            ) {
              return el.number;
            }
            return (
              Math.floor(el.number / 10)
                                === this.selected_data.number_select
                            && el.number % 2 !== 0
            );
          });

          break;
        case 'back_even':
          this.numbers = this.allNumbers.filter((el) => {
            if (
              this.selected_data.number_select === '0'
                            && typeof el.number === 'string'
                            && parseInt(el.number, 10) % 2 === 0
            ) {
              return el.number;
            }
            return (
              Math.floor(el.number / 10)
                                === this.selected_data.number_select
                            && el.number % 2 === 0
            );
          });

          break;
        case 'back_serie':
          this.numbers = this.allNumbers.filter((el) => {
            const toText = el.number.toString();
            const lastChar = toText.slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            return (
              parseInt(this.selected_data.number_select, 10)
                            === lastDigit
            );
          });

          break;
        case 'front_even':
          this.numbers = this.allNumbers.filter((el) => {
            const toText = el.number.toString();
            const lastChar = toText.slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            return (
              parseInt(this.selected_data.number_select, 10)
                                === lastDigit
                            && Math.floor(parseInt(el.number, 10) / 10) % 2 === 0
            );
          });

          break;
        case 'front_odd':
          this.numbers = this.allNumbers.filter((el) => {
            const toText = el.number.toString();
            const lastChar = toText.slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            return (
              parseInt(this.selected_data.number_select, 10)
                                === lastDigit
                            && Math.floor(el.number / 10) % 2 !== 0
            );
          });

          break;
        case 'only_one':
          this.numbers = this.allNumbers.filter((el) => el.number
            .toString()
            .includes(this.selected_data.number_select));

          break;
        case 'brake':
          this.numbers = this.allNumbers.filter((el) => {
            const toText = el.number.toString();
            const lastChar = toText.slice(-1);
            const lastDigit = parseInt(lastChar, 10);
            const total = Math.floor(parseInt(el.number, 10) / 10)
                            + lastDigit;

            if (
              total.toString().length > 1
                            && this.selected_data.number_select === 1
            ) {
              const toTextTotal = total.toString();
              const lastCharTotal = toTextTotal.slice(-1);
              const lastDigitTotal = parseInt(lastCharTotal, 10);
              return (
                lastDigitTotal
                                === this.selected_data.number_select
              );
            }
            return total
              .toString()
              .includes(this.selected_data.number_select);
          });

          break;
        case 'round_with_double':
          this.numbers = this.allNumbers.filter((el) => {
            const newArray = this.selected_data.number_select
              .toString(10)
              .split('')
              .map((t) => t);

            const toText = el.number.toString();
            const lastChar = toText.slice(-1);
            let lastFilter = null;
            let firstFilter = null;

            for (let i = 0; i < newArray.length; i += 1) {
              if (newArray[i] === lastChar) {
                lastFilter = true;
              }
            }
            const firstChar = Math.floor(
              parseInt(el.number, 10) / 10,
            );
            for (let j = 0; j < newArray.length; j += 1) {
              if (newArray[j] === firstChar.toString()) {
                firstFilter = true;
              }
            }

            return firstFilter && lastFilter;
          });

          break;
        case 'round_without_double':
          this.numbers = this.allNumbers.filter((el) => {
            const newArray = this.selected_data.number_select
              .toString(10)
              .split('')
              .map((t) => t);

            const toText = el.number.toString();
            const lastChar = toText.slice(-1);
            let lastFilter = null;
            let firstFilter = null;

            // Math.floor(parseInt(el.number, 10) / 10)
            for (let i = 0; i < newArray.length; i += 1) {
              if (newArray[i] === lastChar) {
                lastFilter = true;
              }
            }
            const firstChar = Math.floor(
              parseInt(el.number, 10) / 10,
            );
            for (let j = 0; j < newArray.length; j += 1) {
              if (newArray[j] === firstChar.toString()) {
                firstFilter = true;
              }
            }

            return (
              firstFilter
                            && lastFilter
                            && firstChar.toString() !== lastChar
            );
          });

          break;
        default:
          break;
      }
    },
    async fetchNumbers() {
      try {
        const res = await axios.post('/twod_numbers', {
          limit: 'all',
          drawTime: this.drawTime
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        this.numbers = res.data.data;
        this.allNumbers = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    drawTime() {
      this.fetchNumbers();
    }
  },
};
</script>
<style scoped>
.badge.badge-pill {
    padding: 10px;
    border: 1px solid transparent;
}
@media (max-width: 425px) {
}
</style>
