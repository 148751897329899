<template>
    <!-- === only amount === -->
    <div class="col-md-4" v-if="modalType === 'one'">
        <div
            class="modal fade"
            :id="id_type"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal- modal-dialog-centered modal-sm"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="card border-0 mb-0">
                            <div class="card-body px-lg-5 py-lg-5">
                                <div class="form-group mb-3">
                                    {{
                                        $store.state.language === "en"
                                            ? "Enter Amount (Amount For all number)"
                                            : "ငွေပမာဏထည့်ပါ(ဂဏန်းအားလုံးအတွတ်)"
                                    }}
                                    <div
                                        class="
                                            mt-2
                                            input-group
                                            input-group-merge
                                            input-group-alternative
                                        "
                                    >
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                ><i
                                                    class="ni ni-money-coins"
                                                ></i
                                            ></span>
                                        </div>

                                        <input
                                            class="form-control"
                                            placeholder="100.00"
                                            type="number"
                                            v-model="amount"
                                            @keyup.enter="sendType('normal')"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.amount
                                    }}</span>
                                </div>
                                <!-- <div class="form-group mb-3" v-if="$route.path.includes('2d')">
                                    {{ $store.state.language === "en" ? "Select Draw Time" : "ထိုးချိန် ရွေးချယ်ပါ" }}
                                    <v-select
                                        v-model="drawTime"
                                        :reduce="(draw) => draw.data"
                                        label="label"
                                        :options="drawOptionGenerator()"
                                    ></v-select>
                                    <span class="text-danger">{{
                                        errors.drawError
                                    }}</span>
                                </div> -->
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="sendType('normal')"
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Continue"
                                                : "ဆက်သွားမည်"
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- === to select one number and amount === -->
    <div class="col-md-4" v-else-if="modalType === 'round'">
        <div
            class="modal fade"
            :id="id_type"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal- modal-dialog-centered modal-sm"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="card border-0 mb-0">
                            <div class="card-body px-lg-5 py-lg-5">
                                <div class="form-group mb-3">
                                    {{
                                        $store.state.language === "en"
                                            ? "Select Number"
                                            : "ဂဏန်းရွေးချယ်ပါ"
                                    }}
                                    <input
                                        class="form-control"
                                        placeholder="123"
                                        type="number"
                                        v-model="selected_data.number_select"
                                        @keyup.enter="focusOnMoneyInputBox()"
                                    />
                                    <span class="text-danger">{{
                                        errors.number
                                    }}</span>
                                </div>
                                <div class="form-group">
                                    {{
                                        $store.state.language === "en"
                                            ? "Enter Amount"
                                            : "ငွေပမာဏထည့်ပါ"
                                    }}
                                    <div
                                        class="
                                            mt-2
                                            input-group
                                            input-group-merge
                                            input-group-alternative
                                        "
                                    >
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                ><i
                                                    class="ni ni-money-coins"
                                                ></i
                                            ></span>
                                        </div>
                                        <input
                                            class="form-control"
                                            placeholder="100.00"
                                            ref="amount"
                                            type="number"
                                            v-model="amount"
                                            @keyup.enter="sendType('normal')"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.amount
                                    }}</span>
                                </div>
                                <!-- <div class="form-group mb-3" v-if="$route.path.includes('2d')">
                                    {{ $store.state.language === "en" ? "Select Draw Time" : "ထိုးချိန် ရွေးချယ်ပါ" }}
                                    <v-select
                                        v-model="drawTime"
                                        :reduce="(draw) => draw.data"
                                        label="label"
                                        :options="drawOptionGenerator()"
                                    ></v-select>
                                </div> -->
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="sendType('normal')"
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Continue"
                                                : "ဆက်သွားမည်"
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- === to select one number and amount === -->
    <div class="col-md-4" v-else>
        <div
            class="modal fade"
            :id="id_type"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal- modal-dialog-centered modal-sm"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="card border-0 mb-0">
                            <div class="card-body px-lg-5 py-lg-5">
                                <div class="form-group mb-3">
                                    {{
                                        $store.state.language === "en"
                                            ? "Select Number"
                                            : "ဂဏန်းရွေးချယ်ပါ"
                                    }}
                                    <TwoDSelect
                                        class="mt-2"
                                        type="normal"
                                        :data="selected_data"
                                        field="number_select"
                                    />
                                    <span class="text-danger">{{
                                        errors.number
                                    }}</span>
                                </div>
                                <div class="form-group">
                                    {{
                                        $store.state.language === "en"
                                            ? "Enter Amount"
                                            : "ငွေပမာဏထည့်ပါ"
                                    }}
                                    <div
                                        class="
                                            mt-2
                                            input-group
                                            input-group-merge
                                            input-group-alternative
                                        "
                                    >
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"
                                                ><i
                                                    class="ni ni-money-coins"
                                                ></i
                                            ></span>
                                        </div>
                                        <input
                                            class="form-control"
                                            ref="amount"
                                            placeholder="100.00"
                                            type="number"
                                            v-model="amount"
                                            @keyup.enter="sendType('advance')"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.amount
                                    }}</span>
                                </div>
                                <!-- <div class="form-group mb-3" v-if="$route.path.includes('2d')">
                                    {{ $store.state.language === "en" ? "Select Draw Time" : "ထိုးချိန် ရွေးချယ်ပါ" }}
                                    <v-select
                                        v-model="drawTime"
                                        :reduce="(draw) => draw.data"
                                        label="label"
                                        :options="drawOptionGenerator()"
                                    ></v-select>
                                </div> -->
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="sendType('advance')"
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Continue"
                                                : "ဆက်သွားမည်"
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TwoDSelect from './TwoDSelect.vue';

export default {
  props: ['selected_data', 'id_type', 'modalType', 'drawTime'],
  components: { TwoDSelect },
  data() {
    return {
      amount: null,
      errors: {},
    //   drawTime: null,
      interval: null,
    };
  },
  watch: {
    'selected_data.number_select': function () {
      if (
        this.selected_data.number_select
                && this.modalType !== 'round'
                && this.modalType !== 'one'
      ) {
        this.focusOnMoneyInputBox();
      }
      console.log(this.selected_data);
    },
  },
  methods: {
    drawOptionGenerator(){
        const currentTime = new Date();

        const morning_mid = new Date();
        morning_mid.setHours(10, 40);

        const morning = new Date();
        morning.setHours(11, 55);

        const evening_mid = new Date();
        evening_mid.setHours(14, 40);

        const evening = new Date();
        evening.setHours(16, 25);

        if(currentTime < morning_mid){
          console.log('morning mid');
            return [
                {
                    data: '10:45',
                    label: '10:45 AM',
                },
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ]
        } else if(currentTime < morning){
          console.log('morning');
            return [
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ]
        } else if(currentTime < evening_mid){
          console.log('evening mid');
            return [
                        {
                            data: '2:45',
                            label: '2:45 PM',
                        },
                        {
                            data: '4:30',
                            label: '4:30 PM',
                        }
                    ]
        } else if(currentTime < evening)   {
          console.log('evening');
          return [
          
              {
                  data: '4:30',
                  label: '4:30 PM',
              }
          ]}
    },
    focusOnMoneyInputBox() {
      this.$refs.amount.focus();
    },
    focusOnSelectBox() {
      document.getElementsByClassName('vs__search')[0].focus();
    },
    sendType(type) {
      this.errors = {};
      if (type !== 'normal') {
        if (!this.selected_data.number_select) {
          this.errors.number = this.$store.state.language === 'en'
            ? 'Please pick a number'
            : 'ဂဏန်းတစ်ခုရွေးချယ်ပါ။';
          return false;
        }
      }
      if (!this.amount) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter amount'
          : 'ငွေပမာဏထည့်ပေးပါ။';
        return false;
      }
      if (this.amount < 100) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
        return false;
      }
      if (!this.drawTime && this.$route.path.includes('2d')) {
        this.errors.drawError = this.$store.state.language === 'en'
          ? 'Please select one draw option.'
          : 'ကျေးဇူးပြု၍ ပေါက်ဂဏန်းထွက်ချိန်တစ်ခုကို ရွေးချယ်ပေးပါ ။';
        return false;
      }
      const findDiffDraw = this.$store.state.twod_slip_data.find(data => data.draw !== this.drawTime)
      if (findDiffDraw) {
        alert(this.$store.state.language === 'en'
          ? 'Please add same draw time.'
          : 'ကျေးဇူးပြု၍ တူညီသော ပေါက်ဂဏန်းထွက်ချိန်ကို ရွေးချယ်ပါ ။'); 
        return false;
      }

      this.$emit('type', {
        id_type: this.id_type,
        amount: this.amount,
        drawTime: this.drawTime
      });
      $(`#${this.id_type}`).modal('hide');
      this.selected_data.number_select = null;
      this.amount = null;
      return true;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  mounted(){
    this.interval = setInterval(() => this.drawOptionGenerator(), 3000);
  }
};
</script>
