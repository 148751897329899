<template>
    <div class="d-flex flex-wrap mt-3">
        <div class="col-xl-12 home-cards">
            <div class="card">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3
                                class="mb-0"
                                v-if="$store.state.language === 'en'"
                            >
                                Recent 3d history
                            </h3>
                            <h3 class="mb-0" v-else>
                                သုံးလုံးထွက်ပြီး ဂဏန်းများ
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <!-- Projects table -->
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr v-if="$store.state.language === 'en'">
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    Date
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    Result
                                </th>
                            </tr>
                            <tr v-else>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    နေ့စွဲ
                                </th>
                                <th
                                    scope="col"
                                    class="text-center table-header"
                                >
                                    ရလဒ်
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(
                                    threeDhistory, index
                                ) in threeDHistories"
                                :key="index"
                            >
                                <td scope="row" class="text-center">
                                    <h3>
                                        {{
                                            threedDrawDateFormat(
                                                threeDhistory.draw_date
                                            )
                                        }}
                                    </h3>
                                </td>
                                <td class="text-center">
                                    <h3>{{ threeDhistory.result }}</h3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="bet-container">
            <button class="btn btn-primary" @click="$router.push('/user/3d')">
                <h3 class="text-uppercase" style="color: #FFD73F">
                    {{
                        $store.state.language === "en"
                            ? "BET"
                            : "ထိုးမည်"
                    }}    
                </h3>
            </button>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      twoDdata: {},
      isLive: false,
      threeDHistories: {},
      interval: null,
      threedData: {},
    };
  },
  methods: {
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDliveresult() {
      try {
        const res = await axios.get('/2d/data/live');
        this.twoDdata = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    fetchLive() {
      this.interval = setInterval(() => this.fetchTwoDliveresult(), 4000);
    },

    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const threeDres = await axios.get('/threed_history');
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmount() {
    clearInterval(this.interval);
  },
  mounted() {
    this.fetchThreedDdata();
    this.fetchTwoThreeDHistories();
  },
};
</script>
<style scoped>
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 70px;
    width: 100px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.main-image-wrapper img {
    width: 100%;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
@keyframes live-number-animation {
    from {
        color: white;
    }
    to {
        color: red;
    }
}

@media (max-width: 768px) {
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 30px;
        z-index: 1;
        font-size: 13px;
        color: #172b4c;
        background: rgba(255, 242, 0, 0.7);
    }
    .live-number {
        font-weight: bold;
        font-size: 20px;
    }
    .live-result {
        font-weight: bolder;
        font-size: 37px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
</style>
