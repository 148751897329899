export const routes = [
  {
    path: '/data-table',
    component: render('frontend/DataTable'),
  },
  {
    path: '/',
    component: render('frontend/Index'),

    children: [
      {
        path: '',
        component: render('frontend/Home'),
        // meta: { auth: true },
      },
      {
        path: 'home',
        component: render('frontend/Home'),
        // meta: { auth: true },
      },
      {
        path: 'rule',
        component: render('frontend/auth/Rule'),
        // meta: { auth: true },
      },
      {
        path: 'auth/login',
        component: render('frontend/auth/Login'),
      },
      {
        path: 'betslip-history',
        component: render('frontend/BetslipHistory'),
        // meta: { auth: true },
      },
      {
        path: 'history',
        component: render('frontend/History'),
        // meta: { auth: true },
      },
      {
        path: 'maung-fb',
        component: render('frontend/MaungFB'),
        // meta: { auth: true },
      },
      {
        path: 'body-fb',
        component: render('frontend/BodyFB'),
        // meta: { auth: true },
      },
      {
        path: 'user',
        component: render('frontend/normal-user/Index'),
        // meta: { auth: true },
        children: [
          {
            path: '2d/home',
            component: render('frontend/normal-user/2d/Home'),
          },

          {
            path: '2d',
            component: render('frontend/normal-user/2d/2d'),
          },

          {
            path: '3d/home',
            component: render('frontend/normal-user/3d/Home'),
          },

          {
            path: '3d',
            component: render('frontend/normal-user/3d/3d'),
          },
          {
            path: 'slots',
            component: render('frontend/normal-user/game/Slot'),
          },
          {
            path: 'fishing',
            component: render('frontend/normal-user/game/Fishing'),
          },
          {
            path: 'slot',
            component: render('frontend/SlotPage'),
          },
          {
            path: 'game',
            component: render('frontend/normal-user/game/Game'),
          },
          {
            path: 'skm-rooms',
            component: render('frontend/normal-user/shankomee/Room'),
          },
          {
            path: 'bugyee-rooms',
            component: render('frontend/normal-user/bugyee/Room'),
          },
          {
            path: 'poker-rooms',
            component: render('frontend/normal-user/poker/Room'),
          },
        ],
      },
      {
        path: 'setting',
        component: render('frontend/Setting'),
        // meta: { auth: true },
      },
      {
        path: 'deposit',
        component: render('frontend/Deposit'),
        // meta: { auth: true },
      },
      {
        path: 'withdraw',
        component: render('frontend/Withdraw'),
        // meta: { auth: true },
      },
      {
        path: 'contact-us',
        component: render('frontend/ContactUs'),
        // meta: { auth: true },
      },
    ],
  },
]

function render(template) {
  return require(`@/pages/${template}.vue`).default;
}
