<template>
    <div>
        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            :text="text"
            v-if="text"
        />
        <h1 class="pt-4 pb-2 text-center">
            {{ $store.state.language === "en" ? "3D" : "သုံးလုံး" }}
        </h1>
        <div class="d-flex p-0 flex-wrap main-wrapper">
            <div class="col-lg-4 col-md-6 px-2 boxes">
                <Table @numbers="getNumbers" :isTwod="false" :allNumbers="$store.state.threed_numbers"/>
            </div>
            <div class="col-lg-4 col-md-6 col-12 px-2 boxes mb-0">
              <NormalBox @numbers="getNumbers" type="threed" :allNumbers="$store.state.threed_numbers"/>
            </div>
            <div class="col-lg-4 col-md-6 px-2 boxes pb-5" style="margin-bottom: 5rem !important">
                <ThreedGroupBox @numbers="getNumbers" />
            </div>
            <div class="col-lg-4 col-md-6 px-2">
                <PreviewVoucher
                    :datas="datas"
                    @remove="removeHistory"
                    class="preview-box"
                    :isTwod="false"
                    type="normal"
                    v-if="!isMobile"
                />
                <div class="row">
                    <!-- === only amount === -->
                    <div class="col-md-4 cart-voucher">
                        <div
                            class="modal fade"
                            id="mobile-voucher"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modal-form"
                            aria-hidden="true"
                        >
                            <div
                                class="
                                    modal-dialog
                                    modal-
                                    modal-dialog-centered
                                    modal-sm
                                "
                                role="document"
                            >
                                <div class="modal-content">
                                    <div
                                        class="text-center modal-cross"
                                        @click="closeModal"
                                    >
                                        X
                                    </div>
                                    <div class="modal-body p-0">
                                        <PreviewVoucher
                                            :datas="datas"
                                            @remove="removeHistory"
                                            :isTwod="false"
                                            v-if="isMobile"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { NoticeBar } from 'vant';
import PreviewVoucher from '../../components/PreviewVoucher.vue';
import Table from '../../components/Table.vue';
import ThreedGroupBox from '../../components/ThreedGroupBox.vue';
import NormalBox from '../../components/NormalBox.vue';

export default {
  components: {
    PreviewVoucher,
    ThreedGroupBox,
    NoticeBar,
    Table,
    NormalBox
  },
  data() {
    return {
      text: '',
      datas: [],
      selected_data: {},
      isMobile: false,
    };
  },
  methods: {
    closeModal() {
      $('#mobile-voucher').modal('hide');
    },
    getNumbers(value) {
      const newValue = JSON.parse(JSON.stringify(value));
      this.datas = [...newValue, ...this.datas];
    },
    removeHistory() {
      this.datas = [];
      this.fetchClosedNumbers();
    },
    async fetchClosedNumbers() {
      try {
        const res = await axios.get('/3d/get_closed_threed_numbers');
        this.text = res.data.data.length > 0
          ? `${
            this.$store.state.language === 'en'
              ? 'Closed number(s)'
              : 'ပိတ်ထားသော နံပါတ်များ'
          } : ${res.data.data.toString()}`
          : null;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchClosedNumbers();
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    if (this.$auth.check()) {
      this.$store.commit(
        'setAmount',
        parseInt(this.$store.state.authUser.amount, 10),
      );
    }
  },
};
</script>
<style scoped>
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
.input-group {
    border: 1px solid #cccccc;
}
.notice-bar {
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
@media (max-width: 768px) {
    .boxes {
        margin-top: 6px;
        margin-bottom: 7px;
    }
    .main-wrapper {
        padding: 0px !important;
    }
}
</style>
