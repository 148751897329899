<template>
    <!-- Page content -->
    <div class="card py-3 px-1 table-card">
        <div class="d-flex flex-wrap justify-content-center">
            <div
                v-for="(num, index) in allNumbers"
                :key="index"
                class="numbers"
                @click="selectNumbers(num, index)"
                :class="[
                    !num.isOpen ||
                    parseInt(num.current_amount, 10) >
                        parseInt(num.limit_amount, 10) ||
                    parseInt(num.current_amount, 10) ===
                        parseInt(num.limit_amount, 10)
                        ? 'disable'
                        : '',
                    num.isSelect ? 'active' : '',
                ]"
            >
                {{ num.number }}
            </div>
        </div>
        <!-- === only amount === -->
        <div class="col-md-4">
            <div
                class="modal fade"
                id="amount"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="card border-0 mb-0">
                                <div class="card-body px-lg-5 py-lg-5">
                                    <div class="form-group">
                                        {{
                                            $store.state.language === "en"
                                                ? "Enter Amount"
                                                : "ငွေပမာဏထည့်ပါ"
                                        }}
                                        <div
                                            class="
                                                mt-2
                                                input-group
                                                input-group-merge
                                                input-group-alternative
                                            "
                                        >
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"
                                                    ><i
                                                        class="
                                                            ni ni-money-coins
                                                        "
                                                    ></i
                                                ></span>
                                            </div>
                                            <input
                                                class="form-control"
                                                placeholder="100.00"
                                                type="number"
                                                v-model="selected_data.amount"
                                                v-on:keyup.enter="
                                                    setToPreviewVoucher()
                                                "
                                            />
                                        </div>
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                    <!-- <div class="form-group mb-3" v-if="$route.path.includes('2d')">
                                        <span class="text-xs">{{ $store.state.language === "en" ? "Select Draw Time" : "ထိုးချိန် ရွေးချယ်ပါ" }}</span>
                                        <v-select
                                            v-model="drawTime"
                                            :reduce="(draw) => draw.data"
                                            label="label"
                                            :options="drawOptionGenerator()"
                                        ></v-select>
                                        <span class="text-danger">{{
                                            errors.drawError
                                        }}</span>
                                    </div> -->
                                    <div class="text-center">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            @click="setToPreviewVoucher()"
                                        >
                                            {{
                                                $store.state.language === "en"
                                                    ? "Continue"
                                                    : "ဆက်သွားမည်"
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bet-container">
                <button class="btn btn-primary" @click="showModalBox" style="height: 35px !important; padding-top: 0.3rem !important">
                    <h3 class="text-uppercase" style="color: #FFD73F">
                        {{
                            $store.state.language === "en"
                                ? "BET"
                                : "ထိုးမည်"
                        }}
                    </h3>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { Dialog } from 'vant';
import createDateWithTimeZone from "../../../libs/createDateWithTimeZone";

export default {
  props: ['isTwod', 'allNumbers', 'drawTime'],
  data() {
    return {
      isR: false,
      numbers: [],
      selected_data: {
        amount: null,
      },
      errors: {},
      // drawOptions: ['10:45', '12:00', '2:45', '4:30'],
      // drawTime: null,
      interval: null
    };
  },
  methods: {
        createDateWithTimeZone() {
          const apiUrl = 'https://worldtimeapi.org/api/timezone/Asia/Yangon';

        // Make a GET request using the fetch function
        fetch(apiUrl)
            .then(response => {
            // Check if the request was successful (status code 2xx)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON response
            return response.json();
            })
            .then(data => {
            const newDateObject = new Date(data.datetime);
            console.log('newDateObject', newDateObject)
            return newDateObject;
            })
            .catch(error => {
            // Handle errors
            console.error('Error fetching data:', error);
            });
      },
    async drawOptionGenerator(){
        const currentTime = await createDateWithTimeZone();

        const morning_mid =  await createDateWithTimeZone();
        morning_mid.setHours(10, 40);

        const morning = await createDateWithTimeZone();
        morning.setHours(11, 55);

        const evening_mid = await createDateWithTimeZone();
        evening_mid.setHours(14, 40);

        const evening =  await createDateWithTimeZone();
        evening.setHours(16, 25);

        if(currentTime < morning_mid){
          console.log('morning mid');
            return [
                {
                    data: '10:45',
                    label: '10:45 AM',
                },
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ]
        } else if(currentTime < morning){
          console.log('morning');
            return [
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ]
        } else if(currentTime < evening_mid){
          console.log('evening mid');
            return [
                        {
                            data: '2:45',
                            label: '2:45 PM',
                        },
                        {
                            data: '4:30',
                            label: '4:30 PM',
                        }
                    ]
        } else if(currentTime < evening)   {
          console.log('evening');
          return [

              {
                  data: '4:30',
                  label: '4:30 PM',
              }
          ]}
    },
    selectNumbers(num, index) {
      this.allNumbers[index].isSelect = !this.allNumbers[index].isSelect;
      if (this.allNumbers[index].isSelect) {
        this.numbers.push(num);
      } else {
        const index = this.numbers.indexOf(num);
        this.numbers.splice(index, 1);
      }
    },
    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
    setToPreviewVoucher() {
      this.errors = {};
      if (!this.selected_data.amount) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter amount'
          : 'ငွေပမာဏထည့်ပေးပါ။';
        return false;
      }
      if (this.selected_data.amount < 100) {
        this.errors.amount = this.$store.state.language === 'en'
          ? 'Please enter at least 100 MMK'
          : 'ငွေပမာဏအနည်းဆုံး ၁၀၀ ထည့်ပေးပါ။';
        return false;
      }
      if (!this.drawTime && this.$route.path.includes('2d')) {
        this.errors.drawError = this.$store.state.language === 'en'
          ? 'Please select one draw option.'
          : 'ကျေးဇူးပြု၍ ပေါက်ဂဏန်းထွက်ချိန်တစ်ခုကို ရွေးချယ်ပေးပါ ။';
        return false;
      }
      const findDiffDraw = this.$store.state.twod_slip_data.find(data => data.draw !== this.drawTime)
      if (findDiffDraw) {
        alert(this.$store.state.language === 'en'
          ? 'Please add same draw time.'
          : 'ကျေးဇူးပြု၍ တူညီသော ပေါက်ဂဏန်းထွက်ချိန်ကို ရွေးချယ်ပါ ။');
        return false;
      }

      this.setAmount(this.selected_data.amount);
      this.$emit('numbers', this.numbers);
      this.selected_data = {};
      this.numbers = [];
      $('#amount').modal('hide');
      $('#mobile-voucher').modal('show');
      this.resultAllSelectedNumbers();
      return true;
    },
    resultAllSelectedNumbers() {
      for (let i = 0; i < this.allNumbers.length; i += 1) {
        this.allNumbers[i].isSelect = false;
      }
    },
    setAmount(amount) {
      // const currentHour = this.createDateWithTimeZone().getHours();
      // const draw = currentHour < 12 ? '12:00' : '4:30';
      for (let i = 0; i < this.numbers.length; i += 1) {
        this.numbers[i].solo_amount = amount;
        this.numbers[i].draw = this.drawTime;
      }
    },
    showModalBox() {
      $('#amount').modal('show');
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  mounted(){
    this.interval = setInterval(() => this.drawOptionGenerator(), 30000);
  }
};
</script>
<style scoped>
.bet-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 70px;
    width: 100px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.25em;
    color: white;
    z-index: 100;
    visibility: visible;
    opacity: 1;
}
.numbers {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}
.numbers.active {
    border-radius: 50%;
    background: #cbe8fe;
}
.numbers.disable {
    border-radius: 50%;
    background: rgb(238, 208, 208);
    color: red;
}
@media (max-width: 768px) {
    .table-data {
        padding: 5px;
    }
    .table-card {
        margin-bottom: 0px;
    }
}
</style>
