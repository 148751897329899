<template>
    <div class="twod_warning">
        <Sticky>
          <NoticeBar
              class="col-lg-12 col-12 mx-auto notice-bar"
              scrollable
              :text="noticeText"
              v-if="noticeText"
          />
        </Sticky>
        <h1 class="pt-4 pb-2 text-center">
            {{ $store.state.language === "en" ? "2D" : "နှစ်လုံး" }}
        </h1>
        <div class="d-flex p-0 flex-wrap main-wrapper">
            <div class="col-lg-4 col-md-6 px-2 boxes">
                <Table @numbers="getNumbers" :drawTime="drawTime" :isTwod="true" :allNumbers="$store.state.twod_numbers"/>
            </div>
            <div class="col-lg-4 col-md-6 col-12 px-2 boxes mb-0">
              <NormalBox @numbers="getNumbers" :drawTime="drawTime" type="twod" :allNumbers="$store.state.twod_numbers"/>
            </div>
            <div class="col-lg-4 col-md-6 col-12 px-2 boxes pb-5 mb-5" style="margin-bottom: 5rem !important">
                <GroupBox @numbers="getNumbers" class="mb-2" :drawTime="drawTime" />
            </div>

            <van-overlay :show="!drawTime">
                <div class="wrapper">
                  <div class="card py-4 px-4 col-7">
                    <h3>Please Select Draw Time</h3>
                    <v-select
                        v-model="drawTime"
                        :reduce="(draw) => draw.data"
                        label="label"
                        class="pb-4"
                        :options="options"
                    ></v-select>
                  </div>
                </div>
            </van-overlay>

            <!-- Preview Voucher -->
            <div class="col-lg-4 col-md-6 px-2">
                <div class="row">
                    <!-- === only amount === -->
                    <div class="col-md-4 cart-voucher">
                        <div
                            class="modal fade"
                            id="mobile-voucher"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="modal-form"
                            aria-hidden="true"
                        >
                            <div
                                class="
                                    modal-dialog
                                    modal-
                                    modal-dialog-centered
                                    modal-sm
                                "
                                role="document"
                            >
                                <div class="modal-content">
                                    <div
                                        class="text-center modal-cross"
                                        @click="closeModal"
                                    >
                                        X
                                    </div>
                                    <div class="modal-body p-0">
                                        <PreviewVoucher
                                            :remark="remark"
                                            v-if="isMobile"
                                            :datas="datas"
                                            @remove="removeHistory"
                                            :isTwod="true"
                                            :drawTime="drawTime"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
import PreviewVoucher from '../../components/PreviewVoucher.vue';
import Table from '../../components/Table.vue';
import GroupBox from '../../components/GroupBox.vue';
import NormalBox from '../../components/NormalBox.vue';
import { NoticeBar, Sticky } from 'vant';
import createDateWithTimeZone from "../../../../libs/createDateWithTimeZone"

export default {
  components: {
    PreviewVoucher,
    Table,
    GroupBox,
    NormalBox,
    NoticeBar,
    Sticky
  },
  data() {
    return {
      drawTime: null,
      datas: [],
      selected_data: {},
      isMobile: false,
      remark: '',
      noticeText: null,
      interval: null,
      options: [],
    };
  },

  methods: {
    async fetchTwoNumbers() {
      try {
        const res = await axios.post('/twod_numbers', {
          limit: 'all',
          drawTime: this.drawTime
        },{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          });
        this.$store.commit('setTwodnumbers', res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    createDateWithTimeZone() {
      const apiUrl = 'https://worldtimeapi.org/api/timezone/Asia/Yangon';

        // Make a GET request using the fetch function
        fetch(apiUrl)
            .then(response => {
            // Check if the request was successful (status code 2xx)
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // Parse the JSON response
            return response.json();
            })
            .then(data => {
            const newDateObject = new Date(data.datetime);
            console.log('newDateObject', newDateObject)
            return newDateObject;
            })
            .catch(error => {
            // Handle errors
            console.error('Error fetching data:', error);
            });
    },
   async drawOptionGenerator(){
        const currentTime = await createDateWithTimeZone();

        console.log("Current", currentTime);

        const morning_mid = await createDateWithTimeZone();
        morning_mid.setHours(10, 35);

        const morning = await createDateWithTimeZone();
        morning.setHours(11, 50);

        const evening_mid = await createDateWithTimeZone();
        evening_mid.setHours(14, 35);

        const evening = await createDateWithTimeZone();
        evening.setHours(15, 50);

        if(currentTime < morning_mid){
          console.log('morning mid');
            this.options =[
                {
                    data: '10:45',
                    label: '10:45 AM',
                },
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ];
        } else if(currentTime < morning){
          console.log('morning');
            this.options = [
                {
                    data: '12:00',
                    label: '12:00 AM',
                },
                {
                    data: '2:45',
                    label: '2:45 PM',
                },
                {
                    data: '4:30',
                    label: '4:30 PM',
                }
            ];
        } else if(currentTime < evening_mid){
          console.log('evening mid');
            this.options = [
                        {
                            data: '2:45',
                            label: '2:45 PM',
                        },
                        {
                            data: '4:30',
                            label: '4:30 PM',
                        }
                    ];
        } else if(currentTime < evening)   {
          console.log('evening');
          this.options= [

              {
                  data: '4:30',
                  label: '4:30 PM',
              }
          ];
        }

        if(currentTime > evening){
            return this.noticeText = this.$store.state.language === "en" ? '2D is closed for Today.' : 'ယနေ့အတွက် နှလုံးထီ ထိုးခြင်း ပိတ်ပါပြီ ။';
        }
    },

    closeModal() {
      $('#mobile-voucher').modal('hide');
    },
    getRemark(value) {
      this.remark = value;
    },
    getNumbers(value) {
      const newValue = JSON.parse(JSON.stringify(value));
      this.datas = [...newValue, ...this.datas];
    },
    removeHistory() {
      this.datas = [];
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  watch: {
    drawTime() {
      this.fetchTwoNumbers();
    }
  },
  async mounted() {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    $('#draw-option-selector').modal('show');
    if (this.$auth.check()) {
      this.$store.commit(
        'setAmount',
        parseInt(this.$store.state.authUser.amount, 10),
      );
    }
    await this.drawOptionGenerator();
    this.interval = setInterval(async() => await this.drawOptionGenerator(), 30000);
  },
};
</script>
<style scoped>
.van-overlay {
  background: rgba(0, 0, 0, 0.85);
  z-index: 10000;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.notice-bar{
  background: #FFD73F;
  color: #481315;
  font-size: 1.2rem;
  height: 30px;
}
.input-group {
    border: 1px solid #cccccc;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}
@media (max-width: 768px) {
    .boxes {
        margin-top: 6px;
        margin-bottom: 7px;
    }
    .main-wrapper {
        padding: 0px !important;
    }
    .preview-box {
        display: none;
    }
}
</style>
