<template>
    <v-select
        v-model="data[field]"
        :reduce="(account) => account.id"
        label="name"
        :options="accounts"
    ></v-select>
</template>
<script>
export default {
  props: ['data', 'field', 'accounts'],
};
</script>
