<template>
	<div
		style="background: linear-gradient(to top, #4d2b7e, #4d2b7e); height: 100vh"
	>
		<div class="login-cont container">
			<div class="row justify-content-center">
				<div class="col-lg-5 col-md-7 d-flex flex-column pt-5">
					<img
						class="mx-auto my-3"
						src="/build/assets/img/brand/logo.png"
						alt=""
						@click="$router.push('/home')"
						style="width: 150px; height: 150px"
					/>

					<div class="header-body text-center">
						<div class="row justify-content-center">
							<div class="col-xl-5 col-lg-6 col-md-8 px-5 pb-5">
								<h1 class="text-white">
									{{
										$store.state.language === "en"
											? "Welcome to V1 ရှမ်းကိုးမီး!"
											: "V1 ရှမ်းကိုးမီး မှကြိုဆိုပါသည်။"
									}}
								</h1>
							</div>
						</div>
					</div>
					<div class="card border-0 mb-0 auth-card">
						<div class="card-body px-lg-5 py-lg-5">
							<div class="text-center mb-4">
								<b>{{
									$store.state.language === "en"
										? "Log in with credentials"
										: "Username နှင့် password ဖြင့် အကောင့်ဝင်မည်။"
								}}</b>
								<div class="error" v-if="error">
									{{
										$store.state.language === "en"
											? "Fail to Login! Please Try Again"
											: "အကောင့်ဝင်ခြင်း ကျဆုံးပါသည်။ တဖန်ပြန်လည် ကြိုးစားကြည့်ပါ။"
									}}
								</div>
							</div>
							<form role="form">
								<small class="text-danger">{{ errors.phone }}</small>
								<div class="form-group mb-3">
									<div
										class="input-group input-group-merge input-group-alternative"
									>
										<div class="input-group-prepend">
											<span class="input-group-text"
												><i class="fas fa-user"></i
											></span>
										</div>
										<input
											class="form-control pl-2"
											placeholder="User Name"
											type="text"
											v-model="name"
											@keyup.enter="focusOnPasswordInputBox"
										/>
									</div>
								</div>
								<small class="text-danger">{{ errors.password }}</small>
								<div class="form-group">
									<div
										class="input-group input-group-merge input-group-alternative"
									>
										<div class="input-group-prepend">
											<span class="input-group-text"
												><i class="ni ni-lock-circle-open"></i
											></span>
										</div>
										<input
											class="form-control pl-2"
											placeholder="Password"
											type="password"
											ref="password"
											v-model="password"
											@keyup.enter="loginWithPhone"
										/>
										<i
											class="far fa-eye showable-password"
											v-if="isShow"
											@click="showablePassword"
										></i>
										<i
											v-if="!isShow"
											class="fas fa-eye-slash showable-password"
											@click="showablePassword"
										></i>
									</div>
								</div>
								<div
									class="custom-control custom-control-alternative custom-checkbox"
								>
									<input
										class="custom-control-input"
										id=" customCheckLogin"
										type="checkbox"
									/>
									<label class="custom-control-label" for=" customCheckLogin">
										<b class="">{{
											$store.state.language === "en"
												? "Remember me"
												: "ဒီအကောင့်ကို မှတ်ထားပေးပါ။"
										}}</b>
									</label>
								</div>
								<div class="text-center">
									<button
										type="button"
										class="btn btn-primary my-4"
										@click="loginWithPhone"
									>
										<div v-if="!isSignin">
											{{
												$store.state.language === "en"
													? "Sign in"
													: "အကောင့် ဝင်မည်။"
											}}
										</div>

										<Loading
											color="#ffffff"
											v-if="isSignin"
											size="24"
											class="px-4 py-0"
										/>
									</button>
								</div>
							</form>
						</div>
					</div>

					<h4 class="text-white mt-4 text-center">
						အကောင့်ဖွင့်ရန် အောက်ပါများထဲမှ တစ်ခုကိုနိပ်ပေးပါ။
					</h4>
					<div class="d-flex justify-content-center mt-2">
						<!-- <a href="https://m.me/shangyionline" class="mx-2">
                            <img src="/build/assets/img/theme/facebook.png" alt="" style="width: 30px; height: 30px;"/>
                        </a>
                        <a href="viber://contact?number=%2B959255652181" class="mx-2">
                            <img src="/build/assets/img/theme/viber.png" alt="" style="width: 30px; height: 30px;"/>
                        </a>
                        <a href="https://t.me/SHANGYI1111" class="mx-2">
                            <img src="/build/assets/img/theme/telegram.png" alt="" style="width: 30px; height: 30px; border-radius: 50%;"/>
                        </a> -->
						<a href="#" class="mx-2">
							<img
								src="/build/assets/img/theme/facebook.png"
								alt=""
								style="width: 30px; height: 30px"
							/>
						</a>
						<a href="#" class="mx-2">
							<img
								src="/build/assets/img/theme/viber.png"
								alt=""
								style="width: 30px; height: 30px"
							/>
						</a>
						<a href="#" class="mx-2">
							<img
								src="/build/assets/img/theme/telegram.png"
								alt=""
								style="width: 30px; height: 30px; border-radius: 50%"
							/>
						</a>
					</div>

					<div class="row mt-3">
						<!-- <div class="col-6 text-center">
                            <a
                                @click="$router.push('/auth/register')"
                                class="new-account-text"
                                ><b>{{
                                    $store.state.language === "en"
                                        ? "Create new account"
                                        : "အကောင့်အသစ်ဖွင့်မည်။"
                                }}</b></a
                            >
                        </div> -->

						<!-- <div class="col-12 text-center">
                            <a
                                data-toggle="modal"
                                data-target="#forgetPassword"
                                class="new-account-text"
                                ><b>{{
                                    $store.state.language === "en"
                                        ? "Forgot Password?"
                                        : "လျို့ဝှက်နံပါတ် မေ့နေပါသလား?"
                                }}</b></a
                            >
                        </div> -->
						<!-- === only amount === -->
						<div class="col-4">
							<div
								class="modal fade"
								id="forgetPassword"
								tabindex="-1"
								role="dialog"
								aria-labelledby="modal-form"
								aria-hidden="true"
							>
								<div
									class="modal-dialog modal- modal-dialog-centered"
									role="document"
								>
									<div class="modal-content">
										<div class="modal-body p-5">
											<b>
												Please direct contact to customer service -
												<span class="text-danger">09756477723</span>
											</b>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Loading, Toast } from "vant";
	import { mapActions } from "vuex";

	export default {
		components: { Loading },
		data() {
			return {
				name: null,
				password: null,
				error: false,
				errors: {},
				isSignin: false,
				isShow: false,
			};
		},
		methods: {
			...mapActions(["fetchUser"]),
			showablePassword() {
				if (this.isShow) {
					this.$refs.password.type = "password";
				} else {
					this.$refs.password.type = "text";
				}
				this.isShow = !this.isShow;
			},
			focusOnPasswordInputBox() {
				this.$refs.password.focus();
			},
			/**
			 * login with phone
			 */
			async loginWithPhone() {
				this.isSignin = true;
				this.errors = {};
				if (!this.name) {
					this.errors.name =
						this.$store.state.language === "en"
							? "Required User Name"
							: "User name လိုအပ်ပါသည်။";
					this.isSignin = false;
					return false;
				}
				if (!this.password) {
					this.errors.password =
						this.$store.state.language === "en"
							? "Required Password"
							: "Password လိုအပ်ပါသည်။";
					this.isSignin = false;
					return false;
				}
				try {
					if (this.name === "testuser") {
						window.open("https://about.v1skm.vip/", "_self");
					} else {
						const res = await this.axios.post(
							"/auth/login",
							{
								name: this.name,
								password: this.password,
								roles: "normal",
							},
							{
								headers: {
									Authorization: `Bearer ${localStorage.getItem("token")}`,
								},
							}
						);
						localStorage.setItem("username", this.name);
						localStorage.setItem("password", this.password);
						localStorage.setItem("token", res.data.token);

						const currentTime = new Date();
						localStorage.setItem(
							"tokenExpire",
							new Date(currentTime.getTime() + 60 * 60 * 1000)
						);
						await this.fetchUser();
						this.$router.push("/home");
						// ..
						Toast.success(
							this.$store.state.language === "en"
								? "Logged In!"
								: "အကောင့်ဝင်ပြီးပါပြီ။"
						);
					}
				} catch (err) {
					this.error = true;
				}
				this.isSignin = false;
				return true;
			},
		},
		async mounted() {
			const isLoggedIn = localStorage.getItem("token");
			const loggedInUser = await this.fetchUser();
			if (!loggedInUser && this.$store.state.authUser.roles === "normal")
				this.$router.push("/home");

			if (isLoggedIn && this.$store.state.authUser.roles === "normal")
				this.$router.push("/home");
			const userName = localStorage.getItem("username");
			const password = localStorage.getItem("password");
			if (userName && password) {
				this.name = userName;
				this.password = password;
			}
		},
	};
</script>

<style scoped>
	.login-cont {
		padding-top: 3rem;
	}
	.showable-password {
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
		z-index: 1000;
	}
	.new-account-text {
		cursor: pointer;
	}
	.error {
		margin-bottom: 13px;
		height: 40px;
		display: flex;
		align-items: center;
		color: red;
		border: 1px solid red;
		border-radius: 8px;
		padding: 0px 15px;
		background: #ff000014;
		font-weight: bold;
	}
	.auth-card {
		background: #eeecec;
		/* color: black */
	}
</style>
