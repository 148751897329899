<template>
    <div class="pt-2" style="background: black !important; min-height: 89vh;">
        <i><h1 class="text-center mt-4" style="color: #f5dd02">
            {{
                $store.state.language === "en"
                    ? "Poker"
                    : "Poker"
            }}
            </h1>
        </i>
        <h3 class="text-white text-center py-4 mt-2">
            {{
                $store.state.language === "en"
                    ? "Please select a Room you preferred."
                    : "သင့်ကြိုက်နှစ်သက်သော အခန်းတစ်ခန်းရွေးချယ်ပေးပါ။"
            }}
        </h3>
        <div class="d-flex flex-wrap justify-content-center flex-shrink-1">
            <div
                @click="fetchShanKoMeeData(0)"
                class="col-6 mt-2"
                style="margin-right: -1rem"
            >
                    <img
                        src="/build/assets/img/theme/rooms/100_room.png"
                        alt=""
                        style="width: 100%;"
                    />

                    <h3 style="color: #f5dd02; margin-top: -0.1rem" class="text-center">အခန်း (၁)</h3>
            </div> 
            <div
                @click="fetchShanKoMeeData(1)"
                class="col-6 mt-2"
            >
                    <img
                        src="/build/assets/img/theme/rooms/300_room.png"
                        alt=""
                        style="width: 100%;"
                    />
                    <h3 style="color: #f5dd02; margin-top: -0.1rem" class="text-center">အခန်း (၂)</h3>
            </div> 
            <div
                @click="fetchShanKoMeeData(2)"
                class="col-6 mt-2"
                style="margin-right: -1rem"
            >
                <img
                    src="/build/assets/img/theme/rooms/500_room.png"
                    alt=""
                    style="width: 100%;"
                />
                <h3 style="color: #f5dd02; margin-top: -0.1rem" class="text-center">အခန်း (၃)</h3>
            </div>   
            <div
                @click="fetchShanKoMeeData(3)"
                class="col-6 mt-2"
            >
                <img
                    src="/build/assets/img/theme/rooms/1000_room.png"
                    alt=""
                    style="width: 100%;"
                />
                <h3 style="color: #f5dd02; margin-top: -0.1rem" class="text-center">အခန်း (၄)</h3>
            </div>   
            <div
                @click="fetchShanKoMeeData(4)"
                class="col-6 mt-2"
                style="margin-right: -1rem"
            >
                <img
                    src="/build/assets/img/theme/rooms/3000_room.png"
                    alt=""
                    style="width: 100%;"
                />
                <h3 style="color: #f5dd02; margin-top: -0.1rem" class="text-center">အခန်း (၅)</h3>
            </div>   
            <div
                @click="fetchShanKoMeeData(5)"
                class="col-6 mt-2"
            >
                <img
                    src="/build/assets/img/theme/rooms/5000_room.png"
                    alt=""
                    style="width: 100%;"
                />
                <h3 style="color: #f5dd02; margin-top: -0.1rem" class="text-center">အခန်း (၆)</h3>
            </div>  
            <!-- <div
                class="col-8 mt-4"
                @click="openCustomFormBox()"
            >
                <img
                    src="/build/assets/img/theme/rooms/customRoom.png"
                    alt=""
                    style="width: 100%;"
                />
                <h3 style="color: #f5dd02;" class="text-center mt-2">ချိန်းဝိုင်း အခန်း</h3>
            </div>     -->
            
        </div>
        <div class="col-4">
            <div
                class="modal fade"
                id="limit"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                @click="hideLimit()"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body my-5 mx-3 text-center">
                         <span class="text-danger text-lg">
                            <b>သတ်မှတ် ထားသော ငွေပမာဏ ရှိမနေပါ။</b> <br><br>
                         </span>

                        <span>{{limitText}}</span>
                        <br><br> <span class="text-dark text-sm">
                            <button class="btn btn-danger" @click="hideLimit()">
                            {{
                                $store.state.language === "en"
                                    ? "Okay"
                                    : "ဟုတ်ပြီ။"
                            }}
                            </button>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-4">
            <div
                class="modal fade"
                id="nickname"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body my-5 mx-3 text-center">
                        <div v-if="isPortrait">
                            <h3 class="text-danger">
                                <i class="fas fa-sync fa-spin text-dark"></i>&nbsp;
                                {{
                                    $store.state.language === "en"
                                        ? "Please rotate your device to landscape mode for the best experience."
                                        : "ကျေးဇူးပြု၍ သင့်၏ mobile phone အား အလျားလိုက်ဖြစ်အောင် လှည့်ပေးပါ။"
                                }}
                                &nbsp;<i class="fas fa-sync fa-spin text-dark"></i>
                            </h3>
                        </div>
                         <span class="text-dark mb-3">
                            <b>Please enter nickname : </b> <br><br>
                         </span>
                         <input
                            class="form-control"
                            type="text"
                            v-model="nickname"
                        />
                        <br><span class="text-dark text-sm">
                            <button class="btn btn-success"  :class="isPortrait ? 'disabled' : ''" @click="isPortrait ? '' : fetchShanKoMeeData()">
                            {{
                                $store.state.language === "en"
                                    ? "Okay"
                                    : "ဟုတ်ပြီ။"
                            }}
                            </button>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- CUSTOM ROOM FORM -->
        <div class="col-md-4">
            <div
                class="modal fade"
                id="custom-form-box"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
                aria-hidden="true"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered modal-sm"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="card border-0 py-3 px-3">
                                <!-- FIRST SETP -->
                                <div class="d-flex flex-column p-3" v-if="step == 1">
                                    <div class="mt-3">
                                        <h3>Creating new room ?</h3>
                                        <button class="col-12 btn btn-success" @click="stepChange(2)">
                                            ROOM CREATE
                                        </button>
                                    </div>
                                    <!-- <div v-if="isSkmPrivateRoom()" class="mt-3 d-flex flex-column border border-muted p-2 px-3" style="border-radius: 5px;">
                                        <h4>Your Shan Ko Mee Room</h4>
                                        <span>ID - <span class="text-success">{{$store.state.authUser.skm_room_id}}</span></span>
                                        <span>Passcode - <span class="text-success">{{$store.state.authUser.skm_room_code}}</span></span>
                                        <span>Expired At - <span class="text-danger">{{expiredDate($store.state.authUser.skm_expired_at)}}</span></span>
                                    </div> -->
                                    
                                    <div>
                                        <h3 class="mt-5">Joining to frend's room ?</h3>
                                        <button class="col-12 btn btn-info" @click="stepChange(3)">
                                            ROOM JOIN
                                        </button>
                                    </div>
                                </div>

                                <div v-if="step === 2">
                                    <!-- SECOND STEP -->
                                    <h3 class="text-success mb-2">
                                        {{
                                            $store.state.language === "en"
                                                ? "Creating New Custom Room"
                                                : "Creating New Custom Room"
                                        }}
                                    </h3>
                                    <div class="mb-4">
                                        <van-checkbox-group v-model="info.level" shape="square" @change="checkedResultChange" class="border border-muted py-3" style="border-radius: 5px;">
                                            <label class="form-control-label ml-3">{{
                                                    $store.state.language === "en"
                                                        ? "Select One Room Type"
                                                        : "Select One Room Type"
                                                }}</label>
                                            <div class="d-flex flex-wrap justify-content-between">
                                                <van-checkbox :name="room.data" class="my-2 col-6" v-for="(room, index) in rooms" :key="index">{{room.label}}</van-checkbox>
                                            </div>
                                        </van-checkbox-group>
                                        <div class="border border-muted px-3 py-2 mt-2" style="border-radius: 5px;">
                                            <div class="form-group">
                                                <label class="form-control-label">{{
                                                    $store.state.language === "en"
                                                        ? "Enter Room Password"
                                                        : "Enter Room Password"
                                                }}</label>
                                                <input
                                                    class="form-control"
                                                    v-model="info.password"
                                                />
                                                <span class="text-danger">{{
                                                    errors.password
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <span class="text-dark text-lg">
                                                {{
                                                    $store.state.language === "en"
                                                        ? "NOTE :"
                                                        : "NOTE :"
                                                }}
                                                </span> 
                                                <span class="text-danger text-md">
                                                    {{
                                                        $store.state.language === "en"
                                                            ? "Creating new custom room will deduct 3,000 MMK from your account. And custom room will be last for 24 hours. You can check Password and Id of custom room in Setting anytime."
                                                            : "ချိန်းဝိုင်း ထောင်ခြင်းသည် သင်၏ အကောင့်ထဲမှ ငွေ 3,000 ကျပ်အားနုတ်ယူသွားပါမည်။ ထို ချိန်းဝိုင်း သည် စတင်ထောင်သည့်အချိန်မှစ၍ 24 နာရီ ကြာချိန်ခံပါမည်။ Password နှင့် ID ကို Setting တွင်သွားရောက်ကြည့်နိုင်ပါသည်။"
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                            type="button"
                                            class="btn btn-success mt-4"
                                            @click="createNewRoom()"
                                        >
                                        <div v-if="!isLoading">
                                            {{
                                                $store.state.language === "en"
                                                    ? "CREATE"
                                                    : "CREATE"
                                            }}
                                        </div>

                                        <Loading
                                            color="#ffffff"
                                            v-if="isLoading"
                                            size="24"
                                            class="px-4 py-0"
                                        />
                                    </button>
                                </div>

                                <div v-if="step === 3">
                                    <!-- SECOND STEP -->
                                    <h3 class="text-success mb-2">
                                        {{
                                            $store.state.language === "en"
                                                ? "Joining to Custom Room"
                                                : "Joining to Custom Room"
                                        }}
                                    </h3>
                                    <div class="mb-4">
                                        <div class="border border-muted px-3 py-2 mt-2" style="border-radius: 5px;">
                                            <span class="text-danger text-capitalize">{{errors.credential}}</span>
                                            <div class="form-group">
                                                <label class="form-control-label">{{
                                                    $store.state.language === "en"
                                                        ? "Enter Room ID"
                                                        : "Enter Room ID"
                                                }}</label>
                                                <input
                                                    class="form-control"
                                                    v-model="joinRoom.id"
                                                />
                                                <span class="text-danger">{{
                                                    errors.id
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label">{{
                                                    $store.state.language === "en"
                                                        ? "Enter Room Password"
                                                        : "Enter Room Password"
                                                }}</label>
                                                <input
                                                    class="form-control"
                                                    v-model="joinRoom.password"
                                                />
                                                <span class="text-danger">{{
                                                    errors.password
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                            type="button"
                                            class="btn btn-success mt-4"
                                            @click="joiningTo()"
                                        >
                                        <div v-if="!isLoading">
                                            {{
                                                $store.state.language === "en"
                                                    ? "JOIN"
                                                    : "JOIN"
                                            }}
                                        </div>

                                        <Loading
                                            color="#ffffff"
                                            v-if="isLoading"
                                            size="24"
                                            class="px-4 py-0"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-overlay :show="isLoading" @click="isLoading = false">
            <div class="wrapper" @click.stop>
              <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import { NoticeBar, Dialog } from 'vant';

export default {
  components: { NoticeBar },
  data() {
    return {
        isLoading: false,
        step: 1,
        isPortrait: window.orientation === 0 || window.orientation === 180,
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
      limitText: '',
      nickname: null,
      level: null,
      rooms: [
        {
            label: '100 Room',
            data: 0,
        },
        {
            label: '300 Room',
            data: 1,
        },
        {
            label: '500 Room',
            data: 2,
        },
        {
            label: '1000 Room',
            data: 3,
        },
        {
            label: '3000 Room',
            data: 4,
        },
        {
            label: '5000 Room',
            data: 5,
        },
      ],
      errors: {
        password: null,
        credential: null,
      },
      info: {
        password: null,
        level: [0],
      },
      joinRoom: {
        password: null,
        id: null,
      },
    };
  },
  methods: {
    checkedResultChange(value){
        if(value.length === 2){
             this.info.level = value.slice(1);
        }
    },
    closeCustomModal(){
        $('#custom-form-box').modal('hide');
    },
    openCustomFormBox(){
        console.log('hello');
        $('#custom-form-box').modal('show');
    },
    stepChange(step){
        this.step = step;
    },
    async createNewRoom() {
        try {   
            this.errors = {};
            this.isLoading = true;
            if (!this.info.password) {
            this.errors.password = this.$store.state.language === 'en'
                ? 'Please enter password'
                : 'Password ထည့်သွင်းပေးပါ။';
            return false;
            }
            this.closeCustomModal();
            // Make a GET request using the fetch function
            let expiredDate = new Date();
            expiredDate.setDate(expiredDate.getDate() + 1);
            this.info = {
                id : this.$store.state.authUser.name,
                level: this.info.level[0],
                password: this.info.password,
                balance : 10000,
                expiredDate,
                info : {
                        nickname : this.$store.state.authUser.user_name,
                        profile : 3
                    }
                }
            const res = await axios.get('/bugyee-create-room', {
                    params: { ...this.info }
                });
                this.isLoading = false;
            window.location.assign(`https://yoeyar-bugyee.vercel.app?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2Fyoeyar.vip/user/bugyee-rooms`)
        } catch (error) {
            this.isLoading = false;
            console.log(error)
        }  
    },
    async joiningTo() {
        try {
            this.errors = {
                credential: null,
            };
            this.isLoading = true;
            if (!this.joinRoom.id) {
            this.errors.id = this.$store.state.language === 'en'
                ? 'Please enter ID'
                : 'ID ထည့်သွင်းပေးပါ။';
            this.isLoading = false;
            return false;
            }
            if (!this.joinRoom.password) {
            this.errors.password = this.$store.state.language === 'en'
                ? 'Please enter password'
                : 'Password ထည့်သွင်းပေးပါ။';
            this.isLoading = false;
            return false;
            }
            this.closeCustomModal();
            // Make a GET request using the fetch function
            this.info = {
                id : this.$store.state.authUser.name,
                balance : 10000,
                roomNo: this.joinRoom.id,
                roomCode: this.joinRoom.password,
                info : {
                        nickname : this.$store.state.authUser.user_name,
                        profile : 3
                    }
                }
            const res = await axios.get('/bugyee-join-room', {
                    params: { ...this.info }
                });

                if(res.data.success){
                    window.location.assign(`https://yoeyar-bugyee.vercel.app?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2Fyoeyar.vip/user/bugyee-rooms`)
                }else{
                    this.errors.credential = res.data.message;
                }
                this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            console.log(error)
        }
        
         
    },
    hideLimit() {
        $('#limit').modal('hide');
    },
    async fetchShanKoMeeData(level = null) {
      try {
        const amount = this.$store.state.authUser.amount;
        this.isLoading = true;
        if(level == 0 && amount < 1000){
            this.limitText = "1,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 1 && amount < 3000){
            this.limitText = "3,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 2 && amount < 5000){
            this.limitText = "5,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 3 && amount < 10000){
            this.limitText = "10,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 4 && amount < 30000){
            this.limitText = "30,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
        if(level == 5 && amount < 50000){
            this.limitText = "50,000 အောက်ရောက်နေပါသည်။ ငွေထပ်မံဖြည့်သွင်းပြီးမှ သည်အခန်းကို ဆော့လို့ရပါမည်။";
            $('#limit').modal('show');
            this.isLoading = false;
            return false;
        }
            const res = await axios.get('/bugyee-data', {
            params: {
                    id : this.$store.state.authUser.name,
                    level,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    }
            });
            window.location.assign(`https://yoeyar-bugyee.vercel.app/?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/user/bugyee-rooms`)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    handleOrientationChange() {
      this.isPortrait = window.orientation === 0 || window.orientation === 180;
    },
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
  async mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.addEventListener('orientationchange', this.handleOrientationChange);
  },
};
</script>
<style scoped>
.card {
    cursor: pointer;
}
.main-image-wrapper img {
    width: 100%;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #172b4c;
    background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

@keyframes live-number-animation {
    from {
        color: white;
    }
    to {
        color: red;
    }
}

@media (max-width: 768px) {
    .middle-container {
        padding-top: 0.1rem !important;
    }
    hr {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 35px;
        z-index: 1;
        font-size: 12px;
        color: #cbe8fe;
        background: black;
    }
    .live-number {
        font-weight: bold;
        font-size: 20px;
    }
    .live-result {
        font-weight: bolder;
        font-size: 37px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .account-container-wrapper {
        width: auto;
        height: auto;
        border-radius: 3px;
        padding: 4px;
        margin: 10px;
        color: black;
        background: #75C2F6;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .top-wrapper,
    .bottom-wrapper {
        padding: 10px;
        width: 100%;
        display: flex;
    }
    .top-right,
    .bottom-right {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: end;
    }
    .top-left,
    .bottom-left {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: start;
    }
}
</style>
