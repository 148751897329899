const createDateWithTimeZone = async () => {
  const apiUrl = "https://worldtimeapi.org/api/timezone/Asia/Yangon";

  try {
    // Make a GET request using the fetch function
    const response = await fetch(apiUrl);

    // Check if the request was successful (status code 2xx)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse the JSON response
    const data = await response.json();
    const newDateObject = new Date(data.datetime);

    return newDateObject;
  } catch (error) {
    // Handle errors
    console.error("Error fetching data:", error);
    return null; // or throw the error
  }
};

export default createDateWithTimeZone;
