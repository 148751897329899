<template>
    <div class="d-flex flex-wrap mt-5 main-wrapper" style="padding-left: 6px !important; padding-right: 6px !important; height: 80vh;">
        <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <nav class="side-nav card">
                <div>
                    <div>
                        <!-- Nav items -->
                        <ul class="navbar-nav-custom">
                            <li class="nav-items" data-toggle="modal" data-target="#editUsername">
                                <div class="nav-link d-flex justify-content-between">
                                    <div>
                                        <i class="fas fa-smile mr-3"></i>
                                        <span class="nav-link-text">{{
                                            $store.state.language === "en"
                                                ? `Nick Name - ${$store.state.authUser.user_name || ''}`
                                                : `Nick Name - ${$store.state.authUser.user_name || ''}`
                                        }}</span>
                                    </div>
                                    <div>
                                            <i class="fas fa-edit text-danger"></i>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-items">
                                <div class="nav-link">
                                    <i class="fas fa-user mr-3"></i>
                                    <span class="nav-link-text">{{
                                        $store.state.language === "en"
                                            ? `User name - ${$store.state.authUser.name}`
                                            : `User name - ${$store.state.authUser.name}`
                                    }}</span>
                                </div>
                            </li>
                            <li class="nav-items">
                                <div class="nav-link">
                                    <i class="fas fa-phone mr-3"></i>
                                    <span class="nav-link-text">{{
                                        $store.state.language === "en"
                                            ? `Phone Number - ${
                                                  $store.state.authUser.phone
                                              }`
                                            : `ဖုန်းနံပါတ် - ${
                                                  $store.state.authUser.phone
                                              }`
                                    }}</span>
                                </div>
                            </li>
                            <li
                                class="nav-items"
                                data-toggle="modal"
                                data-target="#editUserPassword"
                            >
                                <div
                                    class="
                                        nav-link
                                        d-flex
                                        justify-content-between
                                    "
                                >
                                    <div>
                                        <i class="fas fa-key mr-3"></i>
                                        <span class="nav-link-text">{{
                                            $store.state.language === "en"
                                                ? "Change Password"
                                                : "လျှို့ဝှက်နံပါတ် ပြောင်းမည်"
                                        }}</span>
                                    </div>
                                    <div>
                                         <i class="fas fa-edit text-danger"></i>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-items">
                                <div
                                    class="
                                        nav-link
                                        d-flex
                                        justify-content-between
                                    "
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <div id="navbar-language">
                                        <i class="fas fa-language mr-3"></i>
                                        <span class="nav-link-text">{{
                                            $store.state.language === "en"
                                                ? "Change Language"
                                                : "ဘာသာစကား ပြောင်းမည်"
                                        }}</span>
                                    </div>

                                    <div
                                        class="
                                            dropdown-menu dropdown-menu-right
                                        "
                                        aria-labelledby="navbar-language"
                                    >
                                        <a
                                            class="dropdown-item"
                                            @click="setLangLocalstorage('en')"
                                            ><img
                                                src="/build/assets/img/icons/common/united-kingdom.png"
                                                alt=""
                                                class="mr-2"
                                                style="
                                                    width: 20px;
                                                    height: 20px;
                                                "
                                            />
                                            {{
                                                $store.state.language === "en"
                                                    ? "English"
                                                    : "အဂ်လိပ်စာ"
                                            }}</a
                                        >
                                        <div class="dropdown-divider"></div>
                                        <a
                                            class="dropdown-item"
                                            @click="setLangLocalstorage('mm')"
                                            ><img
                                                src="/build/assets/img/icons/common/myanmar.png"
                                                alt=""
                                                class="mr-2"
                                                style="
                                                    width: 20px;
                                                    height: 20px;
                                                "
                                            />{{
                                                $store.state.language === "en"
                                                    ? "Myanmar (Burmese)"
                                                    : "မြန်မာ (ဗမာစာ)"
                                            }}</a
                                        >
                                    </div>

                                    <div>
                                        <i class="fas fa-edit text-danger"></i>
                                    </div>
                                </div>
                            </li>
                            <!-- <li class="nav-items" v-if="isSkmPrivateRoom()">
                                <div class="d-flex flex-column border border-muted p-2 px-3" style="border-radius: 5px;">
                                    <h4>Shan Ko Mee Private Room</h4>
                                    <span>ID - <span class="text-success">{{$store.state.authUser.skm_room_id}}</span></span>
                                    <span>Passcode - <span class="text-success">{{$store.state.authUser.skm_room_code}}</span></span>
                                    <span>Expired At - <span class="text-danger">{{expiredDate($store.state.authUser.skm_expired_at)}}</span></span>
                                </div>
                            </li>
                            <li class="nav-items" v-if="isBugyeePrivateRoom()">
                                <div class="d-flex flex-column border border-muted p-2 px-3" style="border-radius: 5px;">
                                    <h4>Bu Gyee Private Room</h4>
                                    <span>ID - <span class="text-success">{{$store.state.authUser.bugyee_room_id}}</span></span>
                                    <span>Passcode - <span class="text-success">{{$store.state.authUser.bugyee_room_code}}</span></span>
                                    <span>Expired At - <span class="text-danger">{{expiredDate($store.state.authUser.bugyee_expired_at)}}</span></span>
                                </div>
                            </li> -->
                            <li class="nav-items pt-0">
                                <div class="nav-link border border-muted p-2 px-3" style="border-radius: 5px;">
                                    Version : <span class="text-danger">1.0.3</span>
                                </div>
                            </li>
                        </ul>
                        <!-- Divider -->
                        <hr class="my-3" />
                        <!-- Navigation -->
                        <ul class="navbar-nav-custom mb-md-3">
                            <li class="nav-items">
                                <div
                                    class="nav-link active active-pro"
                                    @click="logout()"
                                >
                                    <i
                                        class="
                                            ni ni-lock-circle-open
                                            text-danger
                                            mr-3
                                        "
                                    ></i>
                                    <span class="nav-link-text text-danger">{{
                                        $store.state.language === "en"
                                            ? "Log Out"
                                            : "ထွက်မည်"
                                    }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <!-- ==== modal for user password edit ==== -->
        <div
            class="modal fade"
            id="editUserPassword"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal- modal-dialog-centered modal-sm"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="card border-0 mb-0">
                            <div class="card-body px-lg-5 py-lg-5">
                                <div class="form-group">
                                    {{
                                        $store.state.language === "en"
                                            ? "Old Password"
                                            : "လျို့ဝှက်နံပါတ်အဟောင်း။"
                                    }}
                                    <div class="mt-2 input-group">
                                        <input
                                            class="form-control"
                                            v-model="password.old"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.old
                                    }}</span>
                                </div>
                                <div class="form-group">
                                    {{
                                        $store.state.language === "en"
                                            ? "New Password"
                                            : "လျို့ဝှက်နံပါတ်အသစ်။"
                                    }}
                                    <div class="mt-2 input-group">
                                        <input
                                            class="form-control"
                                            v-model="password.new"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.new
                                    }}</span>
                                </div>
                                <div class="form-group">
                                    {{
                                        $store.state.language === "en"
                                            ? "Confirm Password"
                                            : "အတည်ပြုလျို့ဝှက်နံပါတ်။"
                                    }}
                                    <div class="mt-2 input-group">
                                        <input
                                            class="form-control"
                                            v-model="password.confirm"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.confirm || errors.confirmPassword
                                    }}</span>
                                </div>
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="changeNewPassword"
                                    >
                                        <div v-if="!isConfirm">
                                            {{
                                                $store.state.language === "en"
                                                    ? "Confirm"
                                                    : "အတည်ပြုမည်"
                                            }}
                                        </div>

                                        <Loading
                                            color="#ffffff"
                                            v-if="isConfirm"
                                            size="24"
                                            class="px-4 py-0"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ==== modal for user username edit ==== -->
        <div
            class="modal fade"
            id="editUsername"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modal-form"
            aria-hidden="true"
        >
            <div
                class="modal-dialog modal- modal-dialog-centered modal-sm"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="card border-0 mb-0">
                            <div class="card-body px-lg-5 py-lg-5">
                                <div class="form-group">
                                    {{
                                        $store.state.language === "en"
                                            ? "Update Nickname"
                                            : "Update Nickname"
                                    }}
                                    <div class="mt-2 input-group">
                                        <input
                                            class="form-control"
                                            v-model="nickname.data"
                                        />
                                    </div>
                                    <span class="text-danger">{{
                                        errors.nickname
                                    }}</span>
                                </div>
                                <div class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="changeNickname"
                                    >
                                        <div v-if="!isConfirm">
                                            {{
                                                $store.state.language === "en"
                                                    ? "Confirm"
                                                    : "အတည်ပြုမည်"
                                            }}
                                        </div>

                                        <Loading
                                            color="#ffffff"
                                            v-if="isConfirm"
                                            size="24"
                                            class="px-4 py-0"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      password: {
        user_id: this.$store.state.authUser.id,
      },
      nickname: {
        data: this.$store.state.authUser.user_name,
        user_id: this.$store.state.authUser.id,
      },
      isConfirm: false,
      errors: {},
    };
  },
  methods: {
    isSkmPrivateRoom() {
        const currentTime = new Date();
        const expiredDate = new Date(this.$store.state.authUser.skm_expired_at);
        if(this.$store.state.authUser.skm_room_id || currentTime < expiredDate){
            return true;
        } 
        else false;
    },
    isBugyeePrivateRoom() {
        const currentTime = new Date();
        const expiredDate = new Date(this.$store.state.authUser.bugyee_expired_at);
        if(this.$store.state.authUser.bugyee_room_id || currentTime < expiredDate){
            return true;
        } 
        else false;
    },
    expiredDate(date) {
      return moment(date).format('lll');
    },
    ...mapActions(['logoutUser', 'fetchUser']),
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
    },
    changeNewPassword() {
      this.errors = {};
      if (!this.password.old) {
        this.errors.old = this.$store.state.language === 'en'
          ? 'Old password required'
          : 'လျို့ဝှက်နံပါတ်အဟောင်း ထည့်ပါ။';
        return false;
      }
      if (!this.password.new) {
        this.errors.new = this.$store.state.language === 'en'
          ? 'New password required'
          : 'လျို့ဝှက်နံပါတ်အသစ် ထည့်ပါ။';
        return false;
      }
      if (!this.password.confirm) {
        this.errors.confirm = this.$store.state.language === 'en'
          ? 'Confirm password required'
          : 'အတည်ပြုလျို့ဝှက်နံပါတ် ထည့်ပါ။';
        return false;
      }
      if (this.password.new !== this.password.confirm) {
        this.errors.confirmPassword = this.$store.state.language === 'en'
          ? 'New passwords not match'
          : 'လျှို့ဝှက်နံပါတ် မတူညီပါ။';
        return false;
      }
      this.isConfirm = true;
      Dialog.confirm({
        title:
                    this.$store.state.language === 'en'
                      ? 'Are you sure?'
                      : 'သေချာပါသလား?',
        cancelButtonText:
                    this.$store.state.language === 'en'
                      ? 'Cancel'
                      : 'မလုပ်တော့ပါ။',
        confirmButtonText:
                    this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ။',
      })
        .then(() => {
          this.updateUser();
        })
        .catch(() => {});
      this.isConfirm = false;
      return true;
    },

    changeNickname() {
      this.errors = {};
      if (!this.nickname.data) {
        this.errors.nickname = this.$store.state.language === 'en'
          ? 'Nickname required'
          : 'Nickname required';
        return false;
      }
      this.isConfirm = true;
      Dialog.confirm({
        title:
                    this.$store.state.language === 'en'
                      ? 'Are you sure?'
                      : 'သေချာပါသလား?',
        cancelButtonText:
                    this.$store.state.language === 'en'
                      ? 'Cancel'
                      : 'မလုပ်တော့ပါ။',
        confirmButtonText:
                    this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ။',
      })
        .then(() => {
          this.updateUser('nickname');
        })
        .catch(() => {});
      this.isConfirm = false;
      return true;
    },

    async updateUser(type = null) {
      try {
        const res = await axios.post(
          type === 'nickname' ? 'auth/updateNickname' : 'auth/userPasswordChange',
          type === 'nickname' ? this.nickname : this.password, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        );
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.password = {};
        this.errors = {};
        $('#editUserPassword').modal('hide');

        if(type === 'nickname'){
            await this.fetchUser();
            $('#editUsername').modal('hide');
        }
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။',
        );
      }
    },
    logout() {
      Dialog.confirm({
        title:
                    this.$store.state.language === 'en'
                      ? 'Are you sure to log out?'
                      : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
                    this.$store.state.language === 'en'
                      ? 'Cancel'
                      : 'မထွက်တော့ပါ',
        confirmButtonText:
                    this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en'
              ? 'Logged out!'
              : 'ထွက်ပြီးပြီ',
          );
        })
        .catch(() => {});
    },
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.error {
    margin-bottom: 13px;
    height: 40px;
    display: flex;
    align-items: center;
    color: red;
    border: 1px solid red;
    border-radius: 8px;
    padding: 0px 15px;
    background: #ff000014;
    font-weight: bold;
}
</style>
